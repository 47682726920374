import createClient from '@auth0/auth0-spa-js';
import get from 'lodash/get';
import memoize from 'lodash/memoize';

import { createSegmentURL, redirectSegments } from 'auth/redirects';

export const createAuth0Client = memoize(() => {
  const configuration = get(window, 'QDW_EXPORTS.configuration', {});

  return createClient({
    audience: configuration.auth0Audience,
    client_id: configuration.auth0ClientId,
    domain: configuration.auth0Domain,
    redirect_uri: createSegmentURL(redirectSegments.callback).href,
    scope: configuration.auth0Scope,
    useCookiesForTransactions: true
  });
});
