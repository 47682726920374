import * as $ from 'jquery';
import * as microsoftTeams from '@microsoft/teams-js';

import { createAuth0Client } from 'auth/create-auth0-client';
import { redirectToDefault, redirectSegments, createSegmentURL } from 'auth/redirects';
import { isTeams } from 'teams/is-teams';
import get from 'lodash/get';

$(document).ready(() => {
  $('[data-id=login]').on('click', e => {
    e.preventDefault();

    isTeams() ? teamsLogin() : login();
  });
});

const teamsLogin = () => {
  microsoftTeams.initialize(() => {
    microsoftTeams.authentication.authenticate({
      url: createSegmentURL(redirectSegments.redirector).href,
      width: 700,
      height: 900,
      successCallback: () => redirectToDefault(),
      failureCallback: reason => console.error('MS Teams AuthN failure', reason || 'No reason provided')
    });
  });
};

const login = async () => {
  const configuration = get(window, 'QDW_EXPORTS.configuration', {});
  const auth0Config = configuration.auth0OrganizationId ? { organization: configuration.auth0OrganizationId } : {};

  const client = await createAuth0Client();

  return client.loginWithRedirect(auth0Config);
};
