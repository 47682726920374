export const redirectSegments = {
  default: '/',
  callback: '/auth/auth0/callback',
  redirector: '/auth/auth0/redirector',
  signIn: '/users/sign_in'
};

export const createSegmentURL = segment => new URL(segment, window.location.origin);

export const redirectToSegment = segment => window.location.assign(createSegmentURL(segment).href);

export const redirectToDefault = () => redirectToSegment(redirectSegments.default);

export const redirectToSignIn = () => redirectToSegment(redirectSegments.signIn);
